<template>
  <div class="row layout-generator">
    <div
        :class="item.class"
        :key="idx"
        v-for="(item, idx) in schema"
    >
      <component
          :is="importedComponents[component.type]"
          :key="component.type + idx"
          class="layout-generator--component"
          v-bind="component.props ? component.props : {}"
          v-for="(component, idx) in item.components"
      />
    </div>
  </div>
</template>
<script>

export default {
  name: 'LayoutGenerator',
  props: {
    schema: {
      required: true,
    },
  },
  data() {
    return {
      importedComponents: {},
    };
  },
  beforeMount() {
    this.importComponents();
  },
  methods: {
    importComponents() {
      if (this.schema) {
        this.schema.forEach(column => {
          if (column.components && Array.isArray(column.components)) {
            column.components.forEach(component => {
              this.importedComponents[component.type] = () => import(`./components/${component.type}.vue`);
            });
          }
        });
      }
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.layout-generator {
  &--component {
    .additional-icons {
      > i {
        padding: 7px 9px;
        border-radius: 5px;
        cursor: pointer;
        /*background: #f2f4f6;*/

        &:hover {
          background: #eef0f2;
        }

        &:active {
          background: #e8eaec;
        }
      }
    }
  }
}
</style>
